<template>
  <table class="top-table">
    <thead>
      <tr>
        <td v-for="item in tableHeaders" :key="item">{{ item }}</td>
      </tr>
    </thead>
    <tbody>
      <!-- <tr
        class="current"
        v-if="
          currentUser &&
          !currentUsers.find((item) => item.id === currentUser.id) &&
          (currentUser.date === currentDate || showCurrentUser)
        "
      >
        <td>{{ currentUser.rank || "-" }}</td>
        <td>{{ user.name }}</td>
        <td class="table-time">{{ currentUser.game_time || "-" }}</td>
        <td class="table-score">
          {{ currentUser.percent ? `${currentUser.percent}%` : "-" }}
        </td>
      </tr> -->
      <tr
        v-for="user in currentUsers"
        :class="{ current: user && currentUser && user.id === currentUser.id }"
        :key="user.id"
      >
        <td>{{ user.rank }}</td>
        <td>{{ user.name }}</td>
        <td class="table-time">{{ user.game_time }}</td>
        <td class="table-score">{{ user.score }}%</td>
      </tr>
      <tr v-if="!currentUsers || !currentUsers.length">
        <td colspan="4" class="no-result">Нет результатов турнира</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TopTable",
  components: {},
  props: {
    currentUser: { type: Object },
    currentUsers: { type: Array, default: () => [] },
    currentDate: { type: String },
    showCurrentUser: { type: Boolean, default: false },
  },
  data() {
    return {
      tableHeaders: ["№", "Игрок", "Время", "Правильные ответы"],
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.top-table {
  font-family: "BlenderPro";
  width: 100%;
  border-spacing: 0 3px;
  border-collapse: separate;
  & tr {
    background: rgba(39, 36, 56, 0.8);
  }
  & thead {
    font-size: 16px;
    line-height: 120%;
    color: #7f7e8a;
    & td {
      padding-top: 6px;
      padding-bottom: 11px;
      &:first-child {
        text-align: center;
      }
      &:last-child {
        text-align: end;
        padding-right: 24px;
      }
    }
  }
  & tbody {
    font-size: 24px;
    line-height: 36px;
    color: rgba(255, 255, 255, 0.9);
    & td {
      padding-top: 18px;
      padding-bottom: 18px;
      &:first-child {
        text-align: center;
        font-family: "BlenderPro Bold";
        font-size: 34px;
        line-height: 100%;
        color: rgba(127, 126, 138, 0.3);
        width: 86px;
      }
      &:last-child {
        text-align: end;
        padding-right: 24px;
      }
      &.no-result {
        text-align: center;
      }
    }
    & tr {
      &.current {
        background: #555172;
        border: 2px solid rgba(255, 255, 255, 0.2);
      }
    }
  }
}

.table-time {
  width: 50px;
}

.table-score {
  width: 180px;
}

@media (max-width: 768px) {
  .top-table {
    & thead {
      font-size: 12px;
      & td {
        padding-top: 6px;
        padding-bottom: 6px;
        &:last-child {
          padding-right: 16px;
        }
      }
    }
    & tbody {
      font-size: 16px;
      line-height: 15px;
      & td {
        padding-top: 16px;
        padding-bottom: 16px;
        &:first-child {
          font-size: 18px;
          line-height: 100%;
        }
        &:last-child {
          padding-right: 16px;
        }
      }
    }
  }
}
</style>
