<template>
  <div>{{ stringTime }}</div>
</template>

<script>
function secondsToString(seconds, withHours) {
  const numdays = Math.floor((seconds % 31536000) / 86400) * 24;
  const numhours = (numdays + Math.floor(((seconds % 31536000) % 86400) / 3600))
    .toString()
    .padStart(2, '0');
  const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const numseconds = ((((seconds % 31536000) % 86400) % 3600) % 60)
    .toString()
    .padStart(2, '0');
  if (withHours) {
    return `${numhours}:${numminutes}:${numseconds}`;
  }

  return `${numminutes}:${numseconds}`;
}

export default {
  name: 'DigitalTimer',
  props: {
    interval: {
      type: [String, Number],
      required: true,
    },
    handleEndTime: {
      type: Function,
      default: (ctx) => {
        ctx.stopTimer();
      },
    },
    withHours: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTime: this.interval,
      timer: null,
    };
  },
  computed: {
    stringTime() {
      return secondsToString(this.currentTime, this.withHours);
    },
  },
  watch: {
    interval(val) {
      this.currentTime = val;
    },
  },
  mounted() {
    this.startTimer();
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--;

        if (this.currentTime === 0) {
          this.handleEndTime(this);
          this.stopTimer();
        }
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
    },
  },
};
</script>
